import { Component, ViewEncapsulation } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrls: ['./modal-confirmation.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ModalConfirmationComponent {
  title: string = '';
  description: string = '';
  showButton: boolean = false;
  buttonText: string = '';
  visible: boolean = true; // Propiedad para controlar la visibilidad del modal

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    // Recibir datos del modal
    if (this.config.data) {
      this.title = this.config.data.title || '';
      this.description = this.config.data.description || '';
      this.showButton = this.config.data.showButton || false;
      this.buttonText = this.config.data.buttonText || '';
    }
  }

  onButtonConfirmedClick() {
    if (this.config.data.buttonAction) {
      this.config.data.buttonAction();
    }
    this.ref.close(); // Cerrar el modal
  }

  onButtonCancelClick() {
    this.ref.close(); // Cerrar el modal
  }
}