<div class="dialog-succes">
  <div class="content-container">

    <!-- Título -->
    <div class="flex justify-content-between align-items-center">
      <h2>{{ title }}</h2>
    </div>

    <!-- Mensaje -->
    <div>
      <p>{{ description }}</p>
    </div>

    <!-- Botones -->
    <div class="button-container">
      <button
        *ngIf="showButton"
        pButton
        type="button"
        class="confirm"
        [label]="buttonText"
        (click)="onButtonConfirmedClick()"
      ></button>
      <button
        pButton
        type="button"
        class="cancel"
        label="Cancelar"
        (click)="onButtonCancelClick()"
      ></button>
    </div>
  </div>
</div>