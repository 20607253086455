<ng-container *ngIf="tabs && tabs.length > 0">
  <mat-tab-group class="tab-group" (selectedIndexChange)="onTabChange($event)">
    <mat-tab *ngFor="let tab of tabs" [label]="tab.text | capitalize"></mat-tab>
  </mat-tab-group>
  <br />
</ng-container>

<div class="content-container">
  <!-- Tabla -->
  <div class="table-wrapper" [class.loading]="isLoading">
    <div class="table-container" [ngClass]="{'with-preview': showPreviewCard}" style="position: relative">
      <table mat-table [dataSource]="isLoading ? [] : dataSourceInstance">
        <!-- Columna de selección -->
        <ng-container *ngIf="hasCheckbox" matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef [ngClass]="headerClass"></th>
          <td mat-cell *matCellDef="let row">
            <p-radioButton
              [name]="'rowRadio'"
              [value]="row"
              [(ngModel)]="selectedRow"
              (click)="onRowSelection(row)"
            ></p-radioButton>
          </td>
        </ng-container>

        <!-- Columnas dinámicas -->
        <ng-container
          *ngFor="let column of displayedColumns"
          [matColumnDef]="column.key"
        >
          <th mat-header-cell *matHeaderCellDef [ngClass]="headerClass">
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container
              *ngIf="!customTemplates[column.key]; else customTemplate"
            >
              <!-- Si la columna es anidada, usar getNestedProperty -->
              <ng-container
                *ngIf="isNestedProperty(column.key); else nonNestedProperty"
                [ngClass]="getClassForColumn(column.key)"
              >
                {{ getNestedProperty(element, column.key) }}
              </ng-container>
              <!-- Si la columna no es anidada, acceder directamente -->
              <ng-template #nonNestedProperty>
                {{ element[column.key] }}
              </ng-template>
            </ng-container>
            <ng-template
              #customTemplate
              [ngTemplateOutlet]="customTemplates[column.key]"
              [ngTemplateOutletContext]="{ $implicit: element }"
            ></ng-template>
          </td>
        </ng-container>

        <!-- Columna de acciones -->
        <ng-container matColumnDef="acciones" *ngIf="actionButtons.length > 0">
          <th mat-header-cell *matHeaderCellDef [ngClass]="headerClass">
            Acciones
          </th>
          <td mat-cell *matCellDef="let row">
            <ng-container *ngFor="let button of actionButtons">
              <button
                *ngIf="button?.visible(row)"
                class="custom-icon-button"
                [ngStyle]="{ color: button.color }"
                [matTooltip]="getTooltipClass(button, row)"
                [disabled]="selectedRow !== row"
                (click)="onActionClick(row, button.action)"
              >
                <i [ngClass]="getIconClass(button, row)"></i>
              </button>
            </ng-container>
          </td>
        </ng-container>

        <!-- Header -->
        <tr mat-header-row *matHeaderRowDef="columnKeys"></tr>
        <tr *matNoDataRow>
          <td
            [attr.colspan]="columnKeys.length"
            style="text-align: center; padding: 20px"
          >
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <ng-container *ngIf="isLoading">
                <mat-spinner diameter="30" aria-label="Cargando"></mat-spinner>
              </ng-container>
              <ng-container *ngIf="!isLoading"
                >No hay datos para mostrar</ng-container
              >
            </div>
          </td>
        </tr>

        <!-- Filas -->
        <tr mat-row *matRowDef="let row; columns: columnKeys"></tr>
      </table>
    </div>
    <mat-paginator
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
    ></mat-paginator>
  </div>

  <!-- Card de previsualización -->
  <div class="preview-card" *ngIf="showPreviewCard">
    <!-- Loading spinner mientras se carga el documento -->
    <div class="loading-spinner" *ngIf="isDocumentLoading">
      <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
      <p>Cargando documento...</p>
    </div>

    <!-- Contenido del iframe -->
    <div class="preview-content" *ngIf="selectedDocument && !isDocumentLoading">
      <iframe
        [src]="selectedDocument"
        width="100%"
        height="100%"
        frameborder="0"
        title="PDF Preview"
        (load)="onDocumentLoad()"
        (error)="onDocumentError()"
      ></iframe>
    </div>

    <!-- Mensaje cuando no hay documento -->
    <div
      class="preview-content"
      *ngIf="!selectedDocument && !isDocumentLoading"
    >
      <div class="preview-not-content">
        <div *ngIf="isGeneratingPDF" class="loading-spinner">
          <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
          <p>Generando boleta...</p>
        </div>

        <div *ngIf="!isGeneratingPDF">
          <p>Previsualización no disponible</p>
          <button
            type="button"
            pButton
            *ngIf="showGenerateButton && selectedRow"
            (click)="generateButtonAction()"
            class="generate-document"
          >
            Generar Boleta
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
