import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IComboBase } from '../../interfaces/base/base.interface';

@Component({
  selector: 'app-page-acciones',
  templateUrl: './page-acciones.component.html',
  styleUrls: ['./page-acciones.component.scss'],
})
export class PageAccionesComponent {
  // Configuración para el botón
  @Input() buttonText: string = '';
  @Input() buttonIcon: string = '';
  @Input() buttonColor: string = '';
  @Input() textButtonColor: string = 'white';
  @Output() buttonClick = new EventEmitter<void>();
  @Input() hasButton: boolean = true;

  // Configuración para el botón 2
  @Input() buttonTextSecond: string = '';
  @Input() buttonIconSecond: string = '';
  @Input() buttonColorSecond: string = '';
  @Input() textSecondButtonColor: string = 'white';
  @Output() buttonClickSecond = new EventEmitter<void>();
  @Input() hasButtonSecond: boolean = false;

  // Configuración para el combo auxiliar
  @Input() hasComboAux: boolean = false;
  @Input() comboAuxOptions: IComboBase[] = [];
  @Input() comboAuxSelected: IComboBase | null = null;
  @Output() comboAuxSelectedChange = new EventEmitter<IComboBase>();

  // Configuración para el combo de meses
  @Input() comboOptions: IComboBase[] = [];
  @Input() comboSelected: IComboBase | null = null;
  @Output() comboSelectedChange = new EventEmitter<IComboBase>();

  // Configuración para el combo de años
  @Input() yearOptions: IComboBase[] = [];
  @Input() yearSelected: IComboBase | null = null;
  @Output() yearSelectedChange = new EventEmitter<IComboBase>();

  // Configuración para la barra de búsqueda
  @Input() searchPlaceholder: string = 'Buscar...';
  @Input() searchQuery: string = '';
  @Output() searchQueryChange = new EventEmitter<string>();

  constructor() {
    this.yearOptions = this.generateYearOptions();
    this.yearSelected =
      this.yearOptions.find(
        (year) => year.key === new Date().getFullYear().toString()
      ) || null;
  }

  // Métodos para manejar las acciones
  onButtonClick(): void {
    this.buttonClick.emit();
  }

  // Métodos para manejar las acciones del segundo botón
  onSecondButtonClick(): void {
    this.buttonClickSecond.emit();
  }

  onComboChange(value: IComboBase): void {
    this.comboSelected = value;
    this.comboSelectedChange.emit(value);
  }

  onComboAuxChange(value: IComboBase): void {
    this.comboAuxSelected = value;
    this.comboAuxSelectedChange.emit(value);
  }

  onYearChange(value: IComboBase): void {
    this.yearSelected = value;
    this.yearSelectedChange.emit(value);
  }

  onSearchInput(query: string): void {
    this.searchQuery = query;
    this.searchQueryChange.emit(query);
  }

  // Generar opciones de años dinámicamente (último año + actual)
  private generateYearOptions(): IComboBase[] {
    const currentYear = new Date().getFullYear();
    const years: IComboBase[] = [];

    for (let i = currentYear - 5; i <= currentYear; i++) {
      years.push({ key: i.toString(), text: i.toString() });
    }

    return years;
  }
}
