import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  AfterContentInit,
  ContentChildren,
  QueryList,
  TemplateRef,
  SimpleChanges,
  ChangeDetectorRef,
  OnChanges,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { IComboBase } from '../../interfaces/base/base.interface';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
})
export class PageContainerComponent
  implements OnInit, AfterContentInit, OnChanges
{
  @Input() tabs: IComboBase[] = [];
  @Output() tabSelected = new EventEmitter<string>();
  @Input() displayedColumns: { key: string; header: string }[] = [];
  @Input() actionButtons: {
    icon: ((row: any) => string) | string;
    color: string;
    tooltip: ((row: any) => string) | string;
    action: (row: any) => void;
    visible?: (row: any) => boolean;
  }[] = [];
  @Input() set dataSource(data: any[]) {
    this.dataSourceInstance.data = data;
  }

  @Input() isLoading: boolean = true;
  @Input() selectedRow: any;
  @Input() showPreviewCard: boolean = false;
  @Input() showGenerateButton: boolean = false;
  @Input() generateButtonAction: () => void = () => {};
  @Input() isGeneratingPDF: boolean = false;
  @Input() customTemplates: { [key: string]: TemplateRef<any> } = {};
  @Input() hasCheckbox: boolean = false;

  @Output() rowSelected = new EventEmitter<any>();
  private _selectedDocument: SafeResourceUrl | null = null;

  @Input()
  set selectedDocument(value: SafeResourceUrl | null) {
    this._selectedDocument = value;
    // Forzar la actualización del iframe
    if (value) {
      this.isDocumentLoading = true; // Mostrar el spinner mientras se carga el documento
    }
  }

  get selectedDocument(): SafeResourceUrl | null {
    return this._selectedDocument;
  }

  @Input() headerClass: string = '';

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  columnKeys: string[] = [];
  dataSourceInstance = new MatTableDataSource<any>();
  isDocumentLoading: boolean = false;

  @ContentChildren(TemplateRef) templates!: QueryList<TemplateRef<any>>;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.columnKeys = this.displayedColumns.map((c) => c.key);
    if (this.actionButtons.length > 0) {
      this.columnKeys.push('acciones');
    }

    if (this.hasCheckbox) {
      this.columnKeys.unshift('select');
    }
  }

  ngAfterViewInit(): void {
    this.dataSourceInstance.paginator = this.paginator;
  }

  ngAfterContentInit(): void {
    this.templates.forEach((template) => {
      const columnKey = (
        template as any
      )?.elementRef?.nativeElement?.getAttribute?.('columnKey');
      if (columnKey) {
        this.customTemplates[columnKey] = template;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['selectedDocument'] &&
      changes['selectedDocument'].currentValue
    ) {
      this.isDocumentLoading = true;
    }

    if (changes['selectedRow']) {
      this.onRowSelection(changes['selectedRow'].currentValue);
    }
  }

  // Función para verificar si una clave es una propiedad anidada
  isNestedProperty(key: string): boolean {
    return key.includes('.');
  }

  // Función para acceder a propiedades anidadas
  getNestedProperty(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }

  getClassForColumn(columnKey: string): string {
    const truncateColumns = [
      'nombreTrabajador',
      'situacionLaboral',
      'regimenPensionario',
    ];

    return truncateColumns.includes(columnKey) ? 'truncate-text' : '';
  }

  getIconClass(button: any, row: any): string {
    return typeof button.icon === 'function' ? button.icon(row) : button.icon;
  }

  getTooltipClass (button: any, row: any): string {
    return typeof button.tooltip === 'function' ? button.tooltip(row) : button.tooltip;
  }
  onActionClick(row: any, action: (row: any) => void): void {
    action(row);
  }

  onTabChange(index: number): void {
    const selectedTab = this.tabs[index];
    if (selectedTab) {
      this.tabSelected.emit(selectedTab.key);
    }
  }

  onRowSelection(row: any): void {
    this.rowSelected.emit(row);
    this.selectedRow = row;
    this.cdr.detectChanges();

    if (row && row.paymentSlipUrl) {
      this.isDocumentLoading = true;

      setTimeout(() => {
        if (this.isDocumentLoading) {
          this.isDocumentLoading = false;
          this.cdr.detectChanges();
        }
      }, 1000);
    } else {
      this.isDocumentLoading = false;
      this.cdr.detectChanges();
    }
  }

  onDocumentLoad() {
    this.isDocumentLoading = false;
    this.cdr.detectChanges();
  }

  // Método para manejar errores al cargar el documento en el iframe
  onDocumentError() {
    this.isDocumentLoading = false;
    this.selectedDocument = null;
    this.cdr.detectChanges();
  }
}
