<p-dialog
  header=""
  [(visible)]="visible"
  [modal]="true"
  (onHide)="closeDialog()"
  [closable]="true"
  [style]="{ width: '25vw', height: '25vh' }"
>
  <div class="content-container">
    <div>
      <p>No se puede eliminar el registro, consulte con el área de soporte.</p>
    </div>

      <button
        pButton
        type="button"
        class="cancel"
        label="Entendido"
        (click)="onButtonCancelClick()"
      ></button>
  </div>
</p-dialog>
