<div class="header">
  <div class="actions">
    <div class="button-action">
      <!-- Botón -->
      <ng-container *ngIf="hasButton">
        <button
          mat-raised-button
          [ngStyle]="{
            'background-color': buttonColor,
            color: textButtonColor
          }"
          (click)="onButtonClick()"
        >
          <i [class]="buttonIcon"></i> {{ buttonText }}
        </button>
      </ng-container>

      <!-- Botón -->
      <ng-container *ngIf="hasButtonSecond">
        <button
          mat-raised-button
          [ngStyle]="{
            'background-color': buttonColorSecond,
            color: textSecondButtonColor
          }"
          (click)="onSecondButtonClick()"
        >
          <i [class]="buttonIconSecond"></i> {{ buttonTextSecond }}
        </button>
      </ng-container>
    </div>

    <div class="filters">
      <!-- Combo selector -->
      <div class="combo-select"  *ngIf="hasComboAux">
        <select
          [ngModel]="comboAuxSelected"
          (ngModelChange)="onComboAuxChange($event)"
        >
          <option *ngFor="let option of comboAuxOptions" [ngValue]="option">
            {{ option.text }}
          </option>
        </select>
      </div>

      <!-- Combo selector para Años -->
      <div class="combo-select-year">
        <select [ngModel]="yearSelected" (ngModelChange)="onYearChange($event)">
          <option *ngFor="let year of yearOptions" [ngValue]="year">
            {{ year.text }}
          </option>
        </select>
      </div>

      <!-- Combo selector -->
      <div class="combo-select">
        <select
          [ngModel]="comboSelected"
          (ngModelChange)="onComboChange($event)"
        >
          <option *ngFor="let option of comboOptions" [ngValue]="option">
            {{ option.text }}
          </option>
        </select>
      </div>

      <!-- Barra de búsqueda -->
      <div class="search-bar">
        <i class="pi pi-search search-icon"></i>
        <input
          type="text"
          [placeholder]="searchPlaceholder"
          [ngModel]="searchQuery"
          (ngModelChange)="onSearchInput($event)"
        />
      </div>
    </div>
  </div>
</div>
